<div class="faqs-page-container">
  <div class="header">
    <h1>FAQs</h1>
  </div>

  <div class="faqs-page">
    <div class="faqs-page--section" *ngFor="let faq of faqList; let i = index">
      <div class="content">
        <span [ngClass]="{'active': faq?.isExpand}"></span>
        <p class="que">{{faq?.que}}</p>
        <p class="ans" *ngIf="faq?.isExpand && i != 18">{{faq?.ans}}</p>
        <p class="ans" *ngIf="faq?.isExpand && i == 18">Refer to our Puja Samagri Page
          <a routerLink="/puja-samagri">here</a>
        </p>
      </div>

      <div class="action-arrow">
        <a (click)="onToggleQuestions(i)">
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="endorser-page-container">

  <div class="endorser-page">
    <div class="header">
      <h1>Religious Endorsers</h1>

      <ul>
        <li>
          <a class="pointer-event active">Home </a>
        </li>

        <li>
          <a class="active">
            <i class="fa fa-angle-double-right"></i>
          </a>
        </li>

        <li>
          <a>Religious Endorsers</a>
        </li>
      </ul>
    </div>
  </div>


  <div class="row">
    <div class="col-md-3 content-center">
      <img src="assets/img/shobha.jpg" alt="" />
    </div>

    <div class="col-md-9">
      <h2>Shobha Mehta</h2>
      <p>
        Shobha Mehta has donned many hats throughout her life. She is a teacher,
        mother, pandit and social worker. She commenced her social service
        journey in 1970 by starting the ‘Seven Stars’ in Calcutta to help and
        support kids of local villages with the help of her 7 friends.
      </p>
      <p>
        Later in 1982, she started the Hawan group with the aim of teaching our
        spiritual values and sanskriti (culture) to Indian families living in
        USA. In 1982 she incepted the “India Cultural Association of Central
        Jersey” to promote and teach art, music, cultural sanskaars to families.
      </p>

      <p>
        She has been an active volunteer for various organizations to help save
        and support victims of domestic violence. She was also made the Director
        of Education for “Vivekanand Family Camp”, which is a seven day camp
        focusing on living life in a Vedic way in 2001. She was honoured by
        Governor and Senator Chivukula for her social work and community
        volunteer service in 1990.
      </p>

      <p>
        She is a certified Vedic pandit and started performing weddings in 2012.
        She also started a Bhagwat Gita group last year. Shobha ji loves to knit
        scarves for donations, especially to seniors and healthcare workers.
      </p>

      <p>
        We at <a routerLink="/">FindaPandit.com</a> have the honor of having her as an endorser for our project!
      </p>

      <div class="seprator-line"></div>
    </div>
  </div>

  <div class="row MT-20">
    <div class="col-md-9">
      <h2>Harsh Mendiratta</h2>
      <p>
        Harsh Mendiratta has been living in the USA for the last 25 years. Since
        childhood, he has been interested in spirituality, truth, correct
        worship practices, and intellectual concepts like Indian scriptures. It
        is not surprising that he has keen interest in the concept, encouraged
        the project, and provided valuable feedback.
      </p>

      <p>
        For the last 25 years, Harsh has been leading a social organization
        known as Havan Group. He teaches the core knowledge and deep values
        contained in the Indian scriptures in such a way that children born in
        America can easily learn, understand, and imbibe Vedic knowledge.
      </p>

      <p>
        Harsh also actively participates in Indian cultural events. For the past
        several years, he has been playing the role of King Ravana of Lanka in
        Ramlila, held in New Jersey and New York - the event is also covered in
        newspapers and TV media.
      </p>

      <p>
        By profession, Harsh is a computer engineer and working as a senior
        software architect in the telecom industry. He has more than 25 patents
        in the latest technology sector.
      </p>
    </div>

    <div class="col-md-3 content-center">
      <img src="assets/img/harsh-mendiratta.png" alt="" />
    </div>
  </div>
</div>

<div class="puja-recipes-container">
  <h2 class="page-header">
    <div>{{ data?.title }}</div>
    <span></span>
  </h2>
  <div class="god-details">
    <div class="row">
      <div class="col-md-12">
        <div class="back-to-list">
          <a (click)="backToList()">Back to Puja Recipes</a>
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-md-12" *ngIf="data?.image">
          <div class="image-section">
            <img [src]="'assets/img/recipes/' + data?.image" alt="" />
          </div>
        </div>

        <div class="col-md-5">
          <h4>Ingredients</h4>
          <p [innerHTML]="data?.ingredients"></p>
        </div>

        <div class="col-md-7">
          <h4>Preparation</h4>
          <p [innerHTML]="data?.preparation"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="god-details-container">
  <div class="header">
    <h1>{{data?.title}}</h1>

    <ul>
      <li>
        <a class="pointer-event active">Home </a>
      </li>

      <li>
        <a class="active">
          <i class="fa fa-angle-double-right"></i>
        </a>
      </li>

      <li>
        <a (click)="backToList()">Gods</a>
      </li>

      <li>
        <a class="active">
          <i class="fa fa-angle-double-right"></i>
        </a>
      </li>

      <li>
        <a>God details</a>
      </li>
    </ul>
  </div>

  <div class="god-details">
    <div class="back-to-list">
      <a (click)="backToList()"><i class="fa fa-angle-double-left"></i> Back</a>
    </div>
    <div>
      <div class="god-content">
        <div class="image-section" *ngIf="data?.image">
          <img [src]="'assets/img/gods/' + data?.image" alt="" />
        </div>

        <div class="god-content--body">
          <div>
            <p [innerHTML]="data?.paragraph1"></p>
            <p [innerHTML]="data?.paragraph2"></p>
            <p [innerHTML]="data?.paragraph3"></p>
            <p [innerHTML]="data?.paragraph4"></p>
            <p [innerHTML]="data?.paragraph5"></p>
            <p [innerHTML]="data?.paragraph6"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pricing-section">
<!--Page Content-->
	
	
<div class="rangoli" id="rangolix">
		
    <img class="img-fluid" src="assets/img/Group 165.png" alt="rangoli"/>
    
</div>


<div class="container text-center mb-5" id="heading1">

<h1 class="display-4 text-uppercase font-weight-bolder"><span class="green">Pr </span><span class="orange">icing</span></h1>
    
</div>

<div class="container py-5 my-5 ">

<div class="row">

<div class="col-4 px-3">

    <div class="card text-center no-border special-card">
          <div class="card-header bg-orange no-border card-header-overlap">
            <h2 class="text-white"><strong>$10/</strong><small>per day</small></h2>
          </div>
          <div class="card-body bg-green py-5">
            <h2 class="card-title"><strong>Freemium</strong></h2>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.<br><br>Point 1<br><br>Point 2<br><br>Point 3</p>
          </div>
          <div class="card-footer bg-none no-border card-footer-overlap">
            <a  class="btn btn-block btn-orange"><h4 class="pt-2 text-uppercase">Choose Plan</h4></a>
          </div>
    </div>

</div>

<div class="col-4 px-3">

    <div class="card text-center no-border special-card">
          <div class="card-header bg-orange no-border card-header-overlap">
            <h2 class="text-white"><strong>$10/</strong><small>per day</small></h2>
          </div>
          <div class="card-body bg-light py-5">
            <h2 class="card-title"><strong>Pro</strong></h2>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.<br><br>Point 1<br><br>Point 2<br><br>Point 3</p>
          </div>
          <div class="card-footer bg-none no-border card-footer-overlap">
            <a  class="btn btn-block btn-orange btn-hover-action"><h4 class="pt-2 text-uppercase">Choose Plan</h4></a>
          </div>
    </div>

</div>

<div class="col-4 px-3">

    <div class="card text-center no-border special-card">
          <div class="card-header bg-orange no-border card-header-overlap">
            <h2 class="text-white"><strong>$10/</strong><small>per day</small></h2>
          </div>
          <div class="card-body bg-green py-5">
            <h2 class="card-title"><strong>Advance</strong></h2>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.<br><br>Point 1<br><br>Point 2<br><br>Point 3</p>
          </div>
          <div class="card-footer bg-none no-border card-footer-overlap">
            <a  class="btn btn-block btn-orange"><h4 class="pt-2 text-uppercase">Choose Plan</h4></a>
          </div>
    </div>

</div>


</div>


</div>


<div class="rangoli" id="rangoli1">

    <img class="img-fluid" src="assets/img/Group 165.png" alt="rangoli"/>
    
</div>
</div>
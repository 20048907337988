<div class="yoga-container">
    <div class="header">
        <h1>Vaastu Shastra</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/vaastu.jpg" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>Vaastu Shastra is a Sanskrit term that translates to the "science of architecture." Vaastu
                        Shastra unifies science, art, astronomy, and astrology; it is an ancient mystic science for
                        designing and building.
                    </p>
                    <p>
                        Vaastu is the science of all the five elements of nature and balances them with man and
                        material. Implementation of this science helps create a harmonious setting or a place to live or
                        work, in the most scientific way taking advantage of the benefits bestowed by the five elements
                        called "Paanchbhootas." Vaastu is used to enhance health, wealth, prosperity, and happiness in
                        an enlightened environment.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="announcement-container">
    <div class="header">
        <h1>Application</h1>
    </div>

    <div class="announcement-section">
        <h3>Additional Requirements:  </h3>
        <p>If selected for the Program, you will be required to make the following representations and warranties, and
            agree and acknowledge that Producer will have the following rights with respect to the materials submitted
            by you:</p>

        <ul>
            <li>Each applicant acknowledges, understands and agrees that Producer may disclose any information contained
                within or derived from his or her application to third persons connected with the Program and to compile
                information from third parties in connection with such application and the application process about
                applicant's private, personal and public life, personal relationships with third persons, confidences
                and secrets with family, friends, significant others, including without limitation: physical appearance;
                personal characteristics/habits (both physical and mental); medical treatment/history (both physical and
                mental); sexual history; educational and employment history; military history; criminal investigations,
                charges and records; personal views and opinions about life, the world, politics, religion, and the like
                (collectively, "Personal Information"). Applicant acknowledges and agrees that Producer may reveal such
                Personal Information to third parties in the course of the application process and/or the Program and
                that Applicant releases, discharges, and holds harmless Companies (as defined below) from any and all
                claims and damages arising from such compilation or disclosure of Personal Information.</li>

            <li>Each applicant acknowledges, understands and agrees that he or she, if selected for the Program, may be
                audio and/or videotaped up to twenty-four (24) hours a day, seven (7) days a week while participating in
                the Program (collectively, "Recordings") and that such Recordings will be owned by Producer. </li>

            <li>Applicants acknowledge, understand, and agree that Companies’ (as defined below) use or revelation of
                Personal Information and Recordings as defined in these Eligibility Requirements and Representations may
                be embarrassing, unfavorable, humiliating, and/or derogatory and/or may portray him or her in a false
                light. Each applicant agrees to release, discharge and hold harmless the Companies from any and all
                claims (including, without limitation, claims for slander, libel, defamation, violation of rights of
                privacy, publicity, personality, and/or civil rights, depiction in a false light, intentional or
                negligent infliction of emotional distress, copyright infringement, and/or any other tort and/or damages
                arising from or in any way relating to the submission of an application, participation in the selection
                process, participation in the Program, the use of the Personal Information or Recordings and/or the use
                of the applicant's name, voice, and/or likeness in connection with the Program, or the promotion thereof
                in all media now known or hereafter devised. Applicants are required to sign Releases to this effect. 
            </li>

            <li>All applicants must authorize Producer to conduct a background check.</li>

            <li>All applicants who are selected to proceed in the casting process for the Program must be available to
                travel to New Jersey/New York for screening/filming. (Location and date to be determined and are subject
                to change in Producer's sole discretion).</li>

            <li>All applicants who are selected to travel to production location may be required to undergo physical,
                medical and psychological examinations and testing (to be conducted in Los Angeles by qualified
                personnel selected by the Producer) and meet all physical, medical and psychological requirements, as
                set by Producer in its sole discretion.  </li>

            <li>Applicants who are invited to attend a casting session shall treat all information and materials
                received or acquired about Producer, Companies, the selection process and/or the Program, as strictly
                confidential and shall not disclose or otherwise disseminate any such information to any third party.
                Such applicants must sign a Confidentiality Agreement in the form presented by Producer. </li>

            <li>Upon Producer's request, all applicants chosen to participate as a contestant or an alternate in the
                Program (in Producer’s sole discretion) must agree to sign any additional releases or authorizations
                that Producer, in its sole discretion, deems necessary.</li>

            <li>Applicants who are chosen to participate as a contestant or an alternate in the Program (in Producer’s
                sole discretion) must be available to travel and participate in the Program for selected days over a six
                (6) month period for one (1) year following the submission of the application, and to participate in
                taping additional Program materials and in promotional activities for the Program for selected days
                thereafter upon Producer's request. (Dates and/or location to be determined and subject to change in
                Producer's sole discretion).  </li>

            <li>Applicants understand and agree that the Eligibility Requirements and Additional Requirements may be
                changed, modified, or amended by Producer in its sole discretion from time to time.  </li>
        </ul>

        <a [routerLink]="'/application-form'">I want to apply</a>
    </div>
</div>
<div class="scriptures-page-container">
  <div class="scriptures-page">
    <div class="header">
      <h1>Bhartiya Scriptures</h1>
    </div>

    <div class="scriptures-page__section">
      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>

        <div class="scriptures-page__section--block--body">
          <h2>Vedas</h2>
          <ul>
            <li>Rig Veda</li>
            <li>Sama Veda</li>
            <li>Yajur Veda</li>
            <li>Atharva Veda</li>
            
            <ul>
              <li>
                <h1>Section of Vedas</h1>
              </li>
              <li>Sanhita</li>
              <li>Brahmna</li>
              <li>Aranyak (Upnishads)</li>
            </ul>
          </ul>

          <ul>
            <li>
              <h1>Vadang</h1>
            </li>
            <li>Vyakaran</li>
            <li>Jyotish</li>
            <li>Nirukt</li>
            <li>Shikcha</li>
            <li>Chand</li>
            <li>Kalp Sutras</li>
          </ul>

          <ul>
            <li>
              <h1>Vadang</h1>
            </li>
            <li>Arthved</li>
            <li>Dhanurved</li>
            <li>Gandharvaved</li>
            <li>Ayurved</li>
          </ul>
        </div>
      </div>

      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>
        <div class="scriptures-page__section--block--body">
          <h2>Samritis</h2>
        </div>
      </div>

      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>
        <div class="scriptures-page__section--block--body">
          <h2>Darshan Shastras</h2>
          <ul>
            <li>Poorv Mimansa </li>
            <li>Nyay</li>
            <li>Vaisheshik</li>
            <li>Yog</li>
            <li>Uttar Mimansa (Brahm Sutra)</li>
            <li>Sankhya</li>
          </ul>
        </div>
      </div>

      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>
        <div class="scriptures-page__section--block--body">
          <h2>Puranas</h2>
          <ul>
            <li>
              <h1>Bhagwatam</h1>
            </li>
          </ul>
        </div>
      </div>

      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>
        <div class="scriptures-page__section--block--body">
          <ul>
            <li>Writings of acharyas</li>
            <li>Jagadgurus</li>
            <li>Writings of Rasik & bhakt</li>
            <li>Saint</li>
          </ul>
        </div>
      </div>

      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>
        <div class="scriptures-page__section--block--body">
          <h2>Itihas</h2>
          <ul>
            <li>Ramayan</li>
            <li>MAhabharat</li>
          </ul>
        </div>
      </div>

      <div class="scriptures-page__section--block">
        <div class="scriptures-page__section--block--color"></div>
        <div class="scriptures-page__section--block--body">
          <h2>Bhagavad Gita</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="announcement-container">
    <div class="header">
        <h1>Announcement</h1>
    </div>

    <div class="announcement-section">
        <h3>Announcement</h3>
        <p>We are pleased to announce a new partnership between a Leading Indian Entertainment Platform and
            FindAPandit.com.</p>
        <p>Both will promote each other’s business through social media.</p>
        <p>FindAPandit.com will participate in a web series produced by Leading Indian Entertainment Platform covering
            ‘Indians and Religion in the US’ (Working Title.) The web series episodes will be in New Jersey North, New
            York etc. These will cover various themes such as Pandits, Vaastu experts, Astrologers, Hindu Temples, Jain
            temples, and other religious/spiritual places etc.</p>

        <p>The show will be presented on the largest devotional platforms in the world. The Leading Indian Entertainment
            Platform has 8.89 million subscribers on YouTube and 1.8 million people following their Facebook page and
            making it a large destination for religious related content.</p>

        <a [routerLink]="eligibilityUrl">I am interested in Eligibility</a>
    </div>
</div>
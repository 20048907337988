<div class="pandit-profile-container">
  <div class="header">
    <h1>Pandit Profile</h1>
  </div>

  <div class="pandit-profile">
    <ul class="pandit-profile__main-menu">
      <li>
        <a class="active">Personal Information</a>
      </li>

      <!-- <li>
        <a>Pandit Hours</a>
      </li>

      <li>
        <a>Map</a>
      </li> -->
    </ul>

    <div class="pandit-profile__body">
      <app-pandit-information [panditDetails]="panditDetails"></app-pandit-information>
    </div>
  </div>
</div>

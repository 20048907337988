import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-astrology',
  templateUrl: './astrology.component.html',
  styleUrls: ['./astrology.component.scss']
})
export class AstrologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

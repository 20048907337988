<div class="yoga-container">
    <div class="header">
        <h1>Numerology</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/numerology.jpg" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>Numerology is the study of numbers in your life. It is believed that there is a divine or
                        mystical relationship between a number and coinciding events I one’s life. The numbers can
                        define behavior, destiny, temperament, intelligence, disposition, sexuality, spirituality, and
                        so on. It is also the study of the numerical value of the letters in words, names, and ideas.
                        Hindu numerology has its roots in Vedic ritual symbolism and Samkhya philosophy. Samkhya means
                        number and hence enumeration. As Samkhya system enumerated 25 tattvas (categories) it is likely
                        the philosophy came to be called Samkhya. (https://ithihas.wordpress.com/)
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
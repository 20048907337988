<app-svg-icon></app-svg-icon>
<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<app-header [loginStatus]="isLoggedIn"></app-header>
<div class="page-container">
    <div class="root-pages-container">
        <router-outlet (activate)='onActivate($event)'></router-outlet>
    </div>
</div>
<app-footer *ngIf="isFooterHide"></app-footer>

<div class="yoga-container">
    <div class="header">
        <h1>Tarot Card Reading</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/tarrot.jpg" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>Tarot card reading is one of the forms of fortune-telling. A reader uses a deck of cards to
                        answer the questions presented. Tarot reading is an intuitive reading of what the past is, the
                        present, and what might happen in the future.
                    </p>
                    <p>
                        In South India, they practice Kilim Jyotshim, where a parrot is used to pick a card from a deck
                        of cards to reveal the querent's future. The accuracy of these cards and the method, of course,
                        are debatable. However, that is the version of tarot card reading in India. The cards mainly
                        consisted of Indian god pictures.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
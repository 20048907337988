export * from './about-us/about-us.component';
export * from './blog/blog.component';
export * from './contact-us/contact-us.component';
export * from './pricing/pricing.component';
export * from './puja/puja.component';
export * from './mandir/mandir.component';
export * from './mandir/mandir-profile/mandir-profile.component';
export * from './search-result/search-result.component';
export * from './prayers-chants/prayers-chants.component';
export * from './puja-samagri/puja-samagri.component';
export * from './puja-recipes/puja-recipes.component';
export * from './gods/gods.component';
export * from './upcoming-events/upcoming-events.component';
export * from './faqs/faqs.component';
export *  from './pandits/pandit.component';
export *  from './pandits/pandit-profile/pandit-profile.component';
export *  from './pandits/pandit-profile/dialogs/pandit-review-dialog/pandit-review-dialog.component';
export *  from './pandits/pandit-profile/dialogs/pandit-inquiry-dialog/pandit-inquiry-dialog.component';
export *  from './puja-samagri/puja-samagri-dialog/puja-samagri-dialog.component';
export *  from './gods/god-details/god-details.component';
export *  from './puja-recipes/puja-recipes-details/puja-recipes-details.component';
export *  from './sitemap/sitemap.component';
export *  from './hindu-scriptures/hindu-scriptures.component';
export *  from './endorser/endorser.component';
export *  from './hindu-glossary/hindu-glossary.component';
export *  from './puja-samagri/puja-samagri-details/puja-samagri-details.component';
export *  from './calender/calendar-container/calendar-container.component';
export *  from './calender/calendar-container/calendar-one/calendar-one.component';
export *  from './calender/calendar-container/calendar-two/calendar-two.component';
export *  from './landing-page/landing-page.component';
export *  from './landing-page/landing-testimonial/landing-testimonial.component';
export *  from './testimonials/testimonials.component';
export *  from './testimonials/testimonial-details/testimonial-details.component';
export *  from './why-us/why-us.component';
export * from './crematorium-list/crematorium-list.component';
export * from './crematorium-list/crematorium-details/crematorium-details.component';
export * from './florist-list/florist-list.component';
export * from './florist-list/florist-details/florist-details.component';
export * from './career/career.component';
export * from './partner-listing/partner-listing.component';
export * from './pandits/pandit-profile/pandit-information/pandit-information.component';
export * from './pandits/pandit-profile/pandit-hours/pandit-hours.component';
export * from './pandits/pandit-profile/pandit-map/pandit-map.component';
export * from './pandits/pandit-profile/dialogs/pandit-message-dialog/pandit-message-dialog.component';
export * from './search-result/search-temple-details/search-temple-details.component';
export * from './search-result/search-crematorium-details/search-crematorium-details.component';
export * from './search-result/search-florist-details/search-florist-details.component';
export * from './panchang/panchang.component';

<div class="announcement-container">
    <div class="header">
        <h1>Eligibility</h1>
    </div>

    <div class="announcement-section">
      
        <h3>Eligibility</h3>
        <p>Eligibility requirements to be selected and participate in “Indians and Religion in the US’ (“Program”),
            applicants must meet the following requirements:</p>
        <ul>
            <li>All applicants must be at least twenty-one (21) years of age at the time of application.</li>
            <li>All applicants must be versed in Hindu religious/spiritual customs and knowledge.</li>
            <li>All applicants must be residing in the USA/Canada.</li>
            <li>Applicants may not presently be a candidate for any type of elected US/Canadian political office
                ("Candidate").</li>
            <li>All applicants must have a passport/green card/H1B Visa valid for one (1) year following the submission
                of the application.</li>
            <li>All applicants must be in the field for at least 2 years or more.</li>
            <li>Applicants must never have been convicted of a felony or ever had a restraining order entered against
                them.</li>
        </ul>
        
        <a [routerLink]="applicationUrl">I am interested in Application</a>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarot-card-reading',
  templateUrl: './tarot-card-reading.component.html',
  styleUrls: ['./tarot-card-reading.component.scss']
})
export class TarotCardReadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="why-us-container">
  <div class="why-us">
    <div class="header">
      <h1>Why Join Us?</h1>

      <ul>
        <li>
          <a class="pointer-event active">Home </a>
        </li>

        <li>
          <a class="active">
            <i class="fa fa-angle-double-right"></i>
          </a>
        </li>

        <li>
          <a>Why Join Us?</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="why-us__body">
    <div class="why-us__body--content">
      <h2>Why Join Us <span>Find A Pandit</span></h2>
      <p>
        Find a Pandit is the First Global and Comprehensive Online Network of
        Pandits, Temples, Crematoriums, and Florists and many more aspects of
        religious services at a click.
      </p>
    </div>

    <div class="why-us__body--list">
      <div class="why-us-section">
        <div class="why-us-section__left">
          <img src="assets/img/why-us/quick-search.png" alt="" />
        </div>

        <div class="why-us-section__right">
          <h4>Quick Search</h4>
          <p>
            Our search engine is optimized to allow the users to find you
            quicker than general google search
          </p>
          <ul>
            <li>Personalized page to showcase your services</li>
            <li>Zip code-based search</li>
            <li>Distance base search</li>
            <li>Language based search</li>
            <li>Ritual(s)/Service(s) based search</li>
          </ul>
          <span></span>
        </div>
      </div>

      <div class="why-us-section">
        <div class="why-us-section__left">
          <h4>Branding Promotion</h4>
          <p>
            Our Social media team will work extensively for you and your
            business. This campaign is built-in feature of our proposed cost. We
            will promote you to help build your brand and increase your brand
            visibility.
          </p>
        </div>

        <div class="why-us-section__right">
          <img src="assets/img/why-us/Branding-Promotion.png" alt="" />
          <span></span>
        </div>
      </div>

      <div class="why-us-section">
        <div class="why-us-section__left">
          <img src="assets/img/why-us/Three-Month-Free-Trial-Period.jpg" alt="" />
        </div>

        <div class="why-us-section__right">
          <h4>Three Month Free Trial Period</h4>
          <p>
            We offer a 3 month trial period once you register on our website.
            You can opt for our monthly, quarterly or yearly payment plan on
            completion of the trial period. Our fees is competitively priced.
            Your ROI (Return on Investment) will be recouped after one rendered
            service.
          </p>
          <span></span>
        </div>
      </div>

      <div class="why-us-section">
        <div class="why-us-section__left">
          <h4>Reliability</h4>
          <p>We treat every client as if we are part of their journey. Our clients can expect complete honesty and integrity
            We offer safe and secure way to connect with your clients
            End to end experience on our site is user friendly, seamless, and easy
            </p>
          
        </div>

        <div class="why-us-section__right">
          <img src="assets/img/why-us/Reliability.png" alt="" />
          <span></span>  
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pandit-map',
  templateUrl: './pandit-map.component.html',
  styleUrls: ['./pandit-map.component.scss']
})
export class PanditMapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="footer-main-container">
  <div class="footer">
    <div class="footer__one">
      <h3>Explore</h3>
      <ul>
        <li>
          <a [routerLink]="panditUrl" routerLinkActive="active">Pandit</a>
        </li>
        <li>
          <a [routerLink]="templeUrl" routerLinkActive="active">Temple</a>
        </li>
        <li>
          <a [routerLink]="floristUrl" routerLinkActive="active">Florist</a>
        </li>
        <li>
          <a [routerLink]="crematoriumUrl" routerLinkActive="active">Crematorium</a>
        </li>
        <li>
          <a [routerLink]="shemarooStoreUrl" routerLinkActive="active">Store</a>
        </li>
      </ul>
    </div>

    <div class="footer__two">
      <h3>Company</h3>
      <ul>
        <li>
          <a [routerLink]="aboutusUrl" routerLinkActive="active">About Us</a>
        </li>
        <li>
          <a [routerLink]="contactusUrl" routerLinkActive="active">Contact Us</a>
        </li>
        <li>
          <a [routerLink]="whyusUrl" routerLinkActive="active">Why Join Us?</a>
        </li>
        <li>
          <a target="_blank" href="assets/documents/termsofUse.pdf">Terms & Conditions</a>
        </li>
        <li>
          <a target="_blank" href="assets/documents/privacyPolicy.pdf">Privacy Policy</a>
        </li>
        <li>
          <a target="_blank" href="assets/documents/Refund-Cancellation Policy.pdf">Cancellation/Refund</a>
        </li>
        <li>
          <a [routerLink]="faqsUrl" routerLinkActive="active">Faq’s</a>
        </li>
        <li>
          <a [routerLink]="'/'" routerLinkActive="active">Press</a>
        </li>
        <li>
          <a [routerLink]="careerUrl" routerLinkActive="active">Career</a>
        </li>
      </ul>
    </div>

    <div class="footer__three">
      <h3>Collaborations</h3>
      <ul>
        <li>
          <a [routerLink]="endorsersUrl" routerLinkActive="active">Endorsers</a>
        </li>
        <li>
          <a [routerLink]="partnerListUrl" routerLinkActive="active">Partnerships</a>
        </li>
        <li>
          <a [routerLink]="partnerCollaborateUrl" routerLinkActive="active">Partner With Us</a>
        </li>
        <li>
          <a [routerLink]="'/'" routerLinkActive="active">Advertising</a>
        </li>
      </ul>
    </div>

    <div class="footer__four">
      <h3>Social Media</h3>
      <ul>
        <li>
          <a href="https://www.facebook.com/findapanditofficial" target="_blank"><i class="fa fa-facebook"></i> Facebook</a>
        </li>
        <li>
          <a href="https://www.instagram.com/findapandit" target="_blank"><i class="fa fa-instagram"></i> Instagram</a>
        </li>
        <li>
          <a href="https://twitter.com/find_a_pandit" target="_blank"><i class="fa fa-twitter"></i> Twitter</a>
        </li>
        <li>
          <a href="https://www.pinterest.com/findapandit" target="_blank"><i class="fa fa-pinterest"></i> Pinterest</a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/findapandit" target="_blank"><i class="fa fa-linkedin"></i> Linkedin</a>
        </li>
        <!-- <li>
          <a href="#"><i></i> Linktr.ee</a>
        </li> -->
      </ul>
    </div>

    <div class="footer__five">
      <h3>Portfolio</h3>
      <ul>
        <li>
          <a href="#" target="_blank">Zindagi365</a>
        </li>
        <li>
          <a href="http://shaadipros.com/" target="_blank">Shaadi Pros</a>
        </li>
        <li>
          <a href="https://www.aapkiyaadein.com/" target="_blank">Aapki Yaadein</a>
        </li>
      </ul>
    </div>

    <div class="footer__six">
      <h3>Partners</h3>
      <ul>
        <li>
          <a href="https://tripexplorer.com/" target="_blank"> <img
            [src]="'assets/img/partner-listing/partner-listing-logo.png'" width="120" alt="" /></a>
        </li>
        <li>
          <a href="http://www.actofhumanity.org/" target="_blank"> <img
            [src]="'assets/img/partner-listing/act-humaa.jpeg'" width="120" alt="" /></a>
        </li>
        <li>
          <a href="https://shemarooent.com/" target="_blank"> <img
            [src]="'assets/img/Shemaroo-Entertainment-Limited-Logo.jpeg'" width="120" alt="" /></a>
        </li>
        <li>
          <a href="https://shemaroobhakti.com/" target="_blank"> <img
            [src]="'assets/img/bhakti.jpeg'" width="120" alt="" /></a>
        </li>
        <li>
          <a href="https://www.deewanbanquet.com/" target="_blank"> <img
            [src]="'assets/img/partner-listing/deewanbanquet.png'" width="120" alt="" /></a>
        </li>
      </ul>
    </div>
  </div>

  <div class="footer-bottom">
    <p>© 2018-2022 Zindagi365, LLC. All Rights Reserved.</p>
  </div>
</div>

<!-- <div class="footer-page-container">
  <div class="footer-section">
    <div class="footer-section__body">
      <div class="footer-section__body--footer-main">
        <div class="row">
          <div class="col-md-4">
            <h5>QUICK LINKS</h5>
            <ul>
              <li>
                <a [routerLink]="faqsUrl" routerLinkActive="active">FAQs</a>
              </li>

              <li>
                <a [routerLink]="blogsUrl" routerLinkActive="active">Blogs</a>
              </li>

              <li>
                <a [routerLink]="hinduGlossaryUrl" routerLinkActive="active">Hindu Glossary</a>
              </li>

              <li>
                <a routerLinkActive="active" [routerLink]="testimonialsUrl" class="text-white">Testimonials</a>
              </li>

              <li>
                <a routerLinkActive="active" [routerLink]="whyusUrl" class="text-white">Why Join Us?</a>
              </li>

              <li>
                <a routerLinkActive="active" [routerLink]="careerUrl" class="text-white">Career</a>
              </li>

              <li>
                <a routerLinkActive="active" [routerLink]="partnerListUrl" class="text-white">Partners</a>
              </li>

              <li>
                <a [routerLink]="samskaraUrl" routerLinkActive="active">Samskara</a>
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            <h5>SUPPORT</h5>
            <ul>
              <li>
                <a target="_blank" href="assets/documents/Refund-Cancellation Policy.pdf">Refund / Cancellation
                  Policy</a>
              </li>

              <li>
                <a target="_blank" href="assets/documents/privacyPolicy.pdf">Privacy Policy</a>
              </li>

              <li>
                <a target="_blank" href="assets/documents/termsofUse.pdf" class="text-white">Terms & Conditions</a>
              </li>

              <li>
                <a [routerLink]="contactusUrl" routerLinkActive="active">Contact Us</a>
              </li>

            </ul>
          </div>

          <div class="col-md-4">
            <h5>SOCIALS</h5>
            <ul class="social-icons">
              <li>
                <a matTooltip="Facebook" href="https://www.facebook.com/findapanditofficial" target="_blank">
                  <img src="assets/img/social-icons/facebook.png" width="40" />
                </a>
              </li>

              <li>
                <a matTooltip="Instagram" href="https://www.instagram.com/findapandit" target="_blank">
                  <img src="assets/img/social-icons/instagram.png" width="40" />
                </a>
              </li>

              <li>
                <a matTooltip="Linked In" href="https://www.linkedin.com/company/findapandit" target="_blank">
                  <img src="assets/img/social-icons/linkedin.png" width="40" />
                </a>
              </li>

              <li>
                <a matTooltip="Pinterest" href="https://www.pinterest.com/findapandit" target="_blank">
                  <img src="assets/img/social-icons/pinterest.png" width="40" />
                </a>
              </li>

              <li>
                <a matTooltip="Email" href="mailto:info@findapandit.com">
                  <img src="assets/img/social-icons/email.png" width="40" />
                </a>
              </li>
            </ul>
          </div>

          <div class="col-md-3" *ngIf="false">
            <h5>SUBSCRIBE US</h5>
            <form>
              <div class="row">
                <div class="col-md-12 PL-0">
                  <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Enter Your Email" />
                  </mat-form-field>
                </div>

                <div class="col-md-12 PL-0">
                  <button class="black-btn" mat-raised-button>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="footer-section__body--copyright">
        <p>© 2021 Copyright. All Rights Reserved.</p>
      </div>

    </div>
  </div>
</div>

<div class="footer-container">
  <div class="footer-container__top">
    <div class="footer-left-section">
      <h4>Find us here too!</h4>
      <h3>Social Networks</h3>
      <ul>
        <li>
          <a matTooltip="Facebook" href="https://www.facebook.com/findapanditofficial" target="_blank">
            <i class="fa fa-facebook"></i>
          </a>
        </li>

        <li>
          <a matTooltip="Linked In" href="https://www.linkedin.com/company/findapandit" target="_blank">
            <i class="fa fa-linkedin"></i>
          </a>
        </li>

        <li>
          <a matTooltip="Instagram" href="https://www.instagram.com/findapandit" target="_blank">
            <i class="fa fa-instagram"></i>
          </a>
        </li>

        <li>
          <a matTooltip="Pinterest" href="https://www.pinterest.com/findapandit" target="_blank">
            <i class="fa fa-pinterest-p"></i>
          </a>
        </li>

        <li>
          <a matTooltip="Email" href="mailto:info@findapandit.com">
            <i class="fa fa-envelope-o"></i>
          </a>
        </li>
      </ul>
    </div>

    <div class="footer-right-section">
      <h4>ask us anything!</h4>
      <h3>Get in touch</h3>
      <button (click)="onOpenContactUsPage()" type="button">Contact Us</button>
    </div>
  </div>

  <div class="footer-container__middle">
    <ul>
      <li>
        <a [routerLink]="faqsUrl" routerLinkActive="active">FAQs</a>
      </li>

      <li>
        <a [routerLink]="blogsUrl" routerLinkActive="active">Blogs</a>
      </li>

      <li>
        <a [routerLink]="hinduGlossaryUrl" routerLinkActive="active">Hindu Glossary</a>
      </li>

      <li>
        <a [routerLink]="whyusUrl" routerLinkActive="active">Why Join us</a>
      </li>

      <li>
        <a [routerLink]="partnerListUrl" routerLinkActive="active">Partners</a>
      </li>

      <li>
        <a [routerLink]="careerUrl" routerLinkActive="active">Career</a>
      </li>

      <li>
        <a [routerLink]="yogaUrl" routerLinkActive="active">Yoga</a>
      </li>

      <li>
        <a [routerLink]="astrologuUrl" routerLinkActive="active">Astrology</a>
      </li>

      <li>
        <a [routerLink]="meditationUrl" routerLinkActive="active">Meditation</a>
      </li>

      <li>
        <a [routerLink]="numerologyUrl" routerLinkActive="active">Numerology</a>
      </li>

      <li>
        <a [routerLink]="palmUrl" routerLinkActive="active">Palmistry</a>
      </li>

      <li>
        <a [routerLink]="tarrotUrl" routerLinkActive="active">Tarot Card Reading</a>
      </li>

      <li>
        <a [routerLink]="vaastuUrl" routerLinkActive="active">Vaastu Shastra</a>
      </li>

      <li>
        <a [routerLink]="applicationFormUrl" routerLinkActive="active">Application Form</a>
      </li>
    </ul>
  </div>

  <div class="footer-container__bottom">
    <div class="footer-container__bottom--left">
      <p>© 2021 Copyright. All Rights Reserved.</p>
    </div>

    <div class="footer-container__bottom--right">
      <ul>
        <li>
          <a target="_blank" href="assets/documents/Refund-Cancellation Policy.pdf">Refund / Cancellation Policy</a>
        </li>

        <li>
          <a target="_blank" href="assets/documents/privacyPolicy.pdf">Privacy Policy</a>
        </li>

        <li>
          <a target="_blank" href="assets/documents/termsofUse.pdf">Terms & Conditions</a>
        </li>
      </ul>
    </div>
  </div>
</div> -->

<div class="shemaroo-container">
    <div class="header">
        <h1>Our Product</h1>
    </div>

    <div class="shemaroo-section">
        <div class="shemaroo-product-card">
            <div class="shemaroo-product-card__img">
                <img src="assets/img/store/Shemaroo.png" alt="">
            </div>

            <div class="shemaroo-product-card__content">
                <h2>Our Product Partner</h2>
                <p>Hinduism is known to have 33 million Gods, which makes it a polytheistic religion. The three
                    preeminent Gods portrayed as ‘trinity’ are Brahma (The creator), Vishnu (The protector) and Shiva
                    (The destroyer). Learn about some of the predominant Gods worshipped by Hindus.</p>
            </div>
        </div>

        <div class="product-list">
            <div class="single-product">
                <!-- <img src="assets/img/store/bhajan.jpg" />
                <div class="single-product--descrption">
                    <h3>Shemaroo Bhajan Vaani Specially Curated Famous Bhajan, Aarti...</h3>
                    <ul [class]="{'max-height': !showMore[1]}">
                        <li>Bluetooth speaker with 1008 preloaded Bhakti songs.</li>
                        <li>Bhajans, Aartis, Mantras, Jaaps & Stotras devoted to 8 deities.</li>
                        <li>Featuring Anup Jalota, Suresh Wadkar, Anuradha Paudwal & others.</li>
                        <li>East and quick track selection using remote control.</li>
                    </ul>
                    <div class="show-more-less">
                        <a (click)="onChangeShowToggle(1)">Show <span *ngIf="showMore[1]">less</span>  <span *ngIf="!showMore[1]">more</span></a>
                    </div>
                    <div class="single-product--descrption__price">
                        <label>$69.99</label>
                        <a target="_blank"
                            href="https://www.amazon.com/stores/page/154A83BD-A410-429E-BDBB-E7F7B327BFC6?ingress=0&visitId=8024fb78-fa55-4433-8de5-329d76b34659">BUY
                            NOW</a>
                    </div>
                </div> -->

                <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=findapandit-20&marketplace=amazon&region=US&placement=B07PS6VNCQ&asins=B07PS6VNCQ&linkId=42e2f14793ad30600ca254dbc0c6734c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=ff6501&bg_color=ffffff">
                </iframe>
                
            </div>

            <div class="single-product">
                <!-- <img src="assets/img/store/speaker.jpg" />
                <div class="single-product--descrption">
                    <h3>Shemaroo Ganesh Bhajan Vaani Speaker - Specially Curated Fam...</h3>
                    <ul [class]="{'max-height': !showMore[2]}">
                        <li>Bluetooth speaker with 1008 preloaded Bhakti songs.</li>
                        <li>Bhajans, Aartis, Mantras, Jaaps & Stotras devoted to 8 deities.</li>
                        <li>Featuring Anup Jalota, Suresh Wadkar, Anuradha Paudwal & others.</li>
                        <li>East and quick track selection using remote control.</li>
                    </ul>
                    <div class="show-more-less">
                        <a (click)="onChangeShowToggle(2)">Show <span *ngIf="showMore[2]">less</span>  <span *ngIf="!showMore[2]">more</span></a>
                    </div>
                    <div class="single-product--descrption__price">
                        <label>$39.99</label>
                        <a target="_blank"
                            href="https://www.amazon.com/stores/page/FF63E611-58A8-4867-931A-FD5C2A6CB9B8?ingress=0&visitId=c54866af-6637-41e5-b5a9-755739db963b">BUY
                            NOW</a>
                    </div>
                </div> -->

                <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=findapandit-20&marketplace=amazon&region=US&placement=B07WHYG5WT&asins=B07WHYG5WT&linkId=99db5729052e1709cfbe42a6e8513f3e&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=ff6501&bg_color=ffffff">
                </iframe>
            </div>

            <div class="single-product">
                <!-- <img src="assets/img/store/bhagawadGita.jpg" />
                <div class="single-product--descrption">
                    <h3>Shemaroo Shrimad Bhagavad Gita Wireless Bluetooth Speaker</h3>
                    <ul [class]="{'max-height': !showMore[3]}">
                        <li>Bluetooth speaker with 1008 preloaded Bhakti songs.</li>
                        <li>Bhajans, Aartis, Mantras, Jaaps & Stotras devoted to 8 deities.</li>
                        <li>Featuring Anup Jalota, Suresh Wadkar, Anuradha Paudwal & others.</li>
                        <li>East and quick track selection using remote control.</li>
                    </ul>
                    <div class="show-more-less">
                        <a (click)="onChangeShowToggle(3)">Show <span *ngIf="showMore[3]">less</span>  <span *ngIf="!showMore[3]">more</span></a>
                    </div>
                    <div class="single-product--descrption__price">
                        <label>$69.99</label>
                        <a target="_blank"
                            href="https://www.amazon.com/stores/page/A55D0F34-D08F-4EF0-A09D-C87D0EE1A288?ingress=0&visitId=8024fb78-fa55-4433-8de5-329d76b34659">BUY
                            NOW</a>
                    </div>
                </div> -->

                <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=findapandit-20&marketplace=amazon&region=US&placement=B07PS2Y6HY&asins=B07PS2Y6HY&linkId=c22e92473b81924d5d14d1c0f1cb363b&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=ff6501&bg_color=ffffff">
                </iframe>
            </div>

            <div class="single-product">
                <!-- <img src="assets/img/store/subwoofer.jpg" />
                <div class="single-product--descrption">
                    <h3>Shemaroo Amrit Bani | Premium Gurbani Speaker with Subwoofer...</h3>
                    <ul [class]="{'max-height': !showMore[4]}">
                        <li>Bluetooth speaker with 1008 preloaded Bhakti songs.</li>
                        <li>Bhajans, Aartis, Mantras, Jaaps & Stotras devoted to 8 deities.</li>
                        <li>Featuring Anup Jalota, Suresh Wadkar, Anuradha Paudwal & others.</li>
                        <li>East and quick track selection using remote control.</li>
                    </ul>
                    <div class="show-more-less">
                        <a (click)="onChangeShowToggle(4)">Show <span *ngIf="showMore[4]">less</span>  <span *ngIf="!showMore[4]">more</span></a>
                    </div>
                    <div class="single-product--descrption__price">
                        <label>$79.99</label>
                        <a target="_blank"
                            href="https://www.amazon.com/stores/page/A3AE107D-4369-46B6-A384-1D70004CDFBC?ingress=0&visitId=8024fb78-fa55-4433-8de5-329d76b34659">BUY
                            NOW</a>
                    </div>
                </div> -->

                <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=findapandit-20&marketplace=amazon&region=US&placement=B07Z6HC1T7&asins=B07Z6HC1T7&linkId=4120cc9d8e7be32d71e7eecd099fa344&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=ff6501&bg_color=ffffff">
                </iframe>
            </div>

            <div class="single-product">
                <!-- <img src="assets/img/store/tamilSpeaker.jpg" />
                <div class="single-product--descrption">
                    <h3>Shemaroo Bhakti Maalai | Tamil Devotional Speaker...</h3>
                    <ul [class]="{'max-height': !showMore[5]}">
                        <li>Bluetooth speaker with 1008 preloaded Bhakti songs.</li>
                        <li>Bhajans, Aartis, Mantras, Jaaps & Stotras devoted to 8 deities.</li>
                        <li>Featuring Anup Jalota, Suresh Wadkar, Anuradha Paudwal & others.</li>
                        <li>East and quick track selection using remote control.</li>
                    </ul>
                    <div class="show-more-less">
                        <a (click)="onChangeShowToggle(5)">Show <span *ngIf="showMore[5]">less</span>  <span *ngIf="!showMore[5]">more</span></a>
                    </div>
                    <div class="single-product--descrption__price">
                        <label>$69.99</label>
                        <a target="_blank"
                            href="https://www.amazon.com/stores/page/9E7FC315-6BC5-48C2-A155-3E1271DED8D5?ingress=0&visitId=8024fb78-fa55-4433-8de5-329d76b34659">BUY
                            NOW</a>
                    </div>
                </div> -->

                <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=findapandit-20&marketplace=amazon&region=US&placement=B08H8QD35D&asins=B08H8QD35D&linkId=7237807ea34745c1f05d6405347b3c82&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=ff6501&bg_color=ffffff">
                </iframe>
            </div>
        </div>
    </div>
</div>
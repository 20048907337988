<div class="sitemap-page-container">
  <h2 class="page-header">
    <div>Sitemap</div>
    <span></span>
  </h2>

  <div class="sitemap-page">
    <ul>
      <li>
        <a>Home</a>
      </li>

      <li>
        <a>About US</a>
      </li>

      <li>
        <a>Pandit</a>

        <ul>
            <li>
                <a>Pandit Profile</a>
            </li>
        </ul>
      </li>

      <li>
        <a>Pujas</a>

        <ul>
            <li>
                <a>Puja Details</a>
            </li>
        </ul>
      </li>

      <li>
        <a>Calendar</a>
      </li>

      <li>
        <a>Temples</a>
        <ul>
            <li>
                <a>Temple Profile</a>
            </li>
        </ul>
      </li>

      <li>
        <a>Gods</a>
      </li>

      <li>
        <a>Puja Samagri</a>
      </li>

      <li>
        <a>Prayers/Chants</a>
      </li>

      <li>
        <a>Puja Recipes</a>
      </li>

      <li>
        <a>Calendar</a>
      </li>

      <li>
        <a>Contact US</a>
      </li>

      <!-- <li>
        <a>FAQs</a>
      </li> -->

      <li>
        <a>Privacy Policy</a>
      </li>

      <li>
        <a>Terms & Conditions</a>
      </li>
    </ul>
  </div>
</div>

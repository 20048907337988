<div class="gods-page-container">
  <div class="god-page">
    <div class="header">
      <h1>Temples</h1>
    </div>

    <p>
      Hinduism is known to have 33 million Gods, which makes it a polytheistic
      religion. The three preeminent Gods portrayed as ‘trinity’ are Brahma (The
      creator), Vishnu (The protector) and Shiva (The destroyer). Learn about
      some of the predominant Gods worshipped by Hindus
    </p>

    <div class="god-page__search-section">
        <img src="assets/img/map.jpg" alt="">

        <div>

        </div>
        <h2 class="state-name">Select State Name</h2>
        <div class="god-page__search-section--states">
          <div *ngFor="let item of stateList; let i = index">
            <a (click)="onChangeState(item)" [ngClass]="{'active' : selectedState === item?.state}">{{item?.state}}</a>
          </div>
        </div>
    </div>

    <div *ngIf="templeList && templeList.length > 0">
      <h2>Search Result</h2>
      <div class="god-page__section">
        <div class="god-page__section__box" *ngFor="let item of templeList" (click)="onTempleDetailsPage(item)">
          <img [src]="item?.temple_image ? item?.temple_image : 'assets/img/placeholder-temple.png'" />
          <h2>{{ item?.name }}</h2>
          <p>{{ item?.templeaddress + ', ' + item?.templecity + ', ' + item?.templestate }}</p>
        </div>
      </div>
  </div>

    <div class="god-page__section" *ngIf="false">
      <div class="god-page__section__box" *ngFor="let item of godList" (click)="getTempleDetails(item?.endUrl)">
        <img *ngIf="item?.image" [src]="'assets/img/temples/' + item?.image" />
        <h2>{{ item?.title }}</h2>
        <p>{{ item?.paragraph }}</p>
        <a (click)="getTempleDetails(item?.endUrl)">Read More...</a>
      </div>
    </div>
  </div>
</div>

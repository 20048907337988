<div class="about-us-page-container">
  <div class="about-us">
    <div class="header">
      <h1>About Us</h1>
      <ul>
        <li>
          <a class="pointer-event active">Home </a>
        </li>

        <li>
          <a class="active">
            <i class="fa fa-angle-double-right"></i>
          </a>
        </li>

        <li>
          <a>About Us</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="about-us">
    <div class="row">

      <div class="col-md-12">
        <p class="green">
          <b><a routerLink="/">Find a Pandit</a></b> simplifies all your puja needs by helping you
          find a pandit, providing a list of puja materials and other puja
          arrangements at a click. We bestow highly qualified, experienced
          Pandits/Shastris/Pujaris to do pujas at your home/online. We connect
          you with pandits of your preferred community and language.
        </p>

        <p class="green">
          <b>“Find a Pandit”- First Global Online Pandit Network</b>
        </p>

        <p class="green">
          You will also find informative articles about Hinduism and the
          unanswered questions of the Hindu tradition. Visit our website to Book
          a Pandit, find a list of puja items required, recipes of puja
          delicacies, and find the nearest temple to you.
        </p>

        <p class="green">
          <b><a routerLink="/">Find a Pandit</a></b> that will help you in finding the right pandit for all
          your puja needs. Book your pandit at the earliest! Click on
          <b><a routerLink="/pujas">Find My Puja</a></b> to book your puja, <b><a routerLink="/puja-recipes">Puja Recipes</a></b> to find
          recipes, <b><a routerLink="/puja-samagri">Puja items,</a></b>and to get a list of puja items and
          <b><a routerLink="/temples">Find My Temple</a></b> to find your nearest temple.
        </p>
      </div>

      <div class="col-md-6 text-right MT-20">
        <img src="assets/img/about-us/mission.png" width="350" alt="" />
      </div>

      <div class="col-md-5 text-left MT-20">
        <h3>Vision</h3>
        <p>
          To integrate a specialized and holistic religious service of
          professionals around the globe.
        </p>
        <p>
          This system will transform how professionals pandits view themselves
          and how the larger community values their services.
        </p>
      </div>

      <div class="col-md-6 text-right MT-20">
        <h3>Mission and Objective</h3>
        <p>
          The primary purpose of the Find a Pandit is to provide quality, timely
          and accurate services to the global community around the world that
          are seeking religious services. All inquiries will be met with the
          collective knowledge, skills, service, and expertise of the Find a
          Pandit project staff beginning with the response.
        </p>
      </div>

      <div class="col-md-6 MT-20">
        <img class="MT-20"  src="assets/img/about-us/vision.png" width="350" alt="" />
      </div>

      <div class="core-value MT-20">
        <h3 class="col-md-12 text-center MT-20">Our Core Values</h3>

        <div class="row">
          <div class="col-md-3 col-xs-6">
            <div class="core-value--section">
              <mat-icon svgIcon="reliable"></mat-icon>
              <span>Reliable</span>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="core-value--section">
              <mat-icon svgIcon="accessibility"></mat-icon>
              <span>Accessibility</span>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="core-value--section">
              <mat-icon svgIcon="efficiency"></mat-icon>
              <span>Efficiency</span>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="core-value--section">
              <mat-icon svgIcon="knowledge"></mat-icon>
              <span>Knowledge Transfer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

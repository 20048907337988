<div class="yoga-container">
    <div class="header">
        <h1>Meditation</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/meditation.jpg" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>Meditating is thinking deeply or focusing one's mind for a period, in silence or with the aid of
                        chanting, for religious or spiritual purposes, or as a relaxation method. In Hindu Sanskriti
                        mainly there are three stages of meditation: dharana (concentration), dhyanam (meditation) and
                        samadhim (Enlightenment.)
                    </p>
                    <p>
                        The most popular types of meditation include Vipassana (Mindfulness) and TM (Transcendental),
                        but there are infinitely more meditations than these two.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successful-submission-email',
  templateUrl: './successful-submission-email.component.html',
  styleUrls: ['./successful-submission-email.component.scss']
})

export class SuccessfulSubmissionEmailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

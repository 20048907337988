import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicRoutingModule } from './public-routing.module';
import { SharedModule } from '../shared-module/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxCaptchaModule } from 'ngx-captcha';
import {
  AboutUsComponent,
  BlogComponent,
  ContactUsComponent,
  PricingComponent,
  PujaComponent,
  TempleComponent,
  TempleProfileComponent,
  SearchResultComponent,
  PrayersChantsComponent,
  GodsComponent,
  PujaRecipesComponent,
  PujaSamagriComponent,
  UpcomingEventsComponent,
  FAQsComponent,
  EndorserComponent,
  GodDetailsComponent,
  HinduGlossaryComponent,
  HinduScripturesComponent,
  PanditInquiryDialogComponent,
  PanditProfileComponent,
  PanditReviewDialogComponent,
  PanditsComponent,
  PujaRecipesDetailsComponent,
  PujaSamagriDetailsComponent,
  PujaSamagriDialogComponent,
  SitemapComponent,
  CalendarContainerComponent,
  CalendarOneComponent,
  CalendarTwoComponent,
  LandingPageComponent,
  LandingTestimonialComponent,
  TestimonialsComponent,
  TestimonialDetailsComponent,
  WhyUsComponent,
  CrematoriumListComponent,
  CrematoriumDetailsComponent,
  FloristListComponent,
  FloristDetailsComponent,
  CareerComponent,
  PanditHoursComponent,
  PanditInformationComponent,
  PanditMapComponent,
  PanditMessageDialogComponent,
  PartnerListingComponent,
  SearchCrematoriumDetailsComponent,
  SearchFloristDetailsComponent,
  SearchTempleDetailsComponent,
  PanchangComponent,
} from './components';
import { ContactUsThankYouPageComponent } from './components/contact-us/contact-us-thank-you-page/contact-us-thank-you-page.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { SamskaraComponent } from './components/samskara/samskara.component';
import { PanditImageDialogComponent } from './components/pandits/pandit-profile/dialogs/pandit-image-dialog/pandit-image-dialog.component';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { AstrologyComponent } from './components/astrology/astrology.component';
import { MeditationComponent } from './components/meditation/meditation.component';
import { NumerologyComponent } from './components/numerology/numerology.component';
import { PalmistryComponent } from './components/palmistry/palmistry.component';
import { ShemarooStoreComponent } from './components/shemaroo-store/shemaroo-store.component';
import { TarotCardReadingComponent } from './components/tarot-card-reading/tarot-card-reading.component';
import { VaastuShastraComponent } from './components/vaastu-shastra/vaastu-shastra.component';
import { YogaComponent } from './components/yoga/yoga.component';
import {
  AnnouncementComponent,
  ApplicationComponent,
  EligibilityComponent,
  SuccessfulSubmissionEmailComponent,
} from './components/announcement';

@NgModule({
  declarations: [
    AboutUsComponent,
    BlogComponent,
    ContactUsComponent,
    PricingComponent,
    PujaComponent,
    TempleComponent,
    PanditsComponent,
    TempleProfileComponent,
    PanditProfileComponent,
    SearchResultComponent,
    PrayersChantsComponent,
    PujaSamagriComponent,
    PujaRecipesComponent,
    GodsComponent,
    UpcomingEventsComponent,
    FAQsComponent,
    PanditReviewDialogComponent,
    PanditInquiryDialogComponent,
    PujaSamagriDialogComponent,
    GodDetailsComponent,
    PujaRecipesDetailsComponent,
    SitemapComponent,
    HinduScripturesComponent,
    EndorserComponent,
    HinduGlossaryComponent,
    PujaSamagriDetailsComponent,
    BlogDetailsComponent,
    PanditMessageDialogComponent,
    CalendarContainerComponent,
    CalendarOneComponent,
    CalendarTwoComponent,
    LandingPageComponent,
    LandingTestimonialComponent,
    TestimonialsComponent,
    TestimonialDetailsComponent,
    WhyUsComponent,
    CareerComponent,
    PartnerListingComponent,
    PanditInformationComponent,
    PanditHoursComponent,
    PanditMapComponent,
    CrematoriumListComponent,
    CrematoriumDetailsComponent,
    FloristListComponent,
    FloristDetailsComponent,
    SearchTempleDetailsComponent,
    SearchCrematoriumDetailsComponent,
    SearchFloristDetailsComponent,
    ContactUsThankYouPageComponent,
    SamskaraComponent,
    PanditImageDialogComponent,
    YogaComponent,
    VaastuShastraComponent,
    TarotCardReadingComponent,
    PalmistryComponent,
    MeditationComponent,
    NumerologyComponent,
    AstrologyComponent,
    ShemarooStoreComponent,
    ApplicationFormComponent,
    AnnouncementComponent,
    EligibilityComponent,
    ApplicationComponent,
    SuccessfulSubmissionEmailComponent,
    PanchangComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxCaptchaModule,
    PdfViewerModule,
    PublicRoutingModule,
  ],
  entryComponents: [
    PanditReviewDialogComponent,
    PanditInquiryDialogComponent,
    PujaSamagriDialogComponent,
    ContactUsThankYouPageComponent,
    PanditImageDialogComponent,
  ],
})
export class PublicModule {}

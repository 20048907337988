export class ApiContant {
    public static IMAGE_URL = 'https://fp-resume.s3.amazonaws.com/media/';
    public static URL_WITHOUT_URL = 'https://djapi.findapandit.com/';
    public static URL = 'https://djapi.findapandit.com/api/';
    public static API = 'api/v1/';
    public static BASE_URL = `${ApiContant.URL}`;
    public static FIND_POSTCODE = `${ApiContant.BASE_URL}search`;
    public static FIND_ADDRESS = `${ApiContant.BASE_URL}search`;
    public static GRANUL_SEARCH = `${ApiContant.BASE_URL}granularsearch/`;
    public static TEMPLE_SEARCH = `${ApiContant.BASE_URL}templesearch/`;
    public static CREMITORIUM_SEARCH = `${ApiContant.URL_WITHOUT_URL}crematorium/search/`;
    public static FLORIST_SEARCH = `${ApiContant.URL_WITHOUT_URL}florist/search/`;
    public static TEMPLE_SEARCH_DETAILS = `${ApiContant.BASE_URL}templedetail/`;
    public static CREMITORIUM_SEARCH_DETAILS = `${ApiContant.URL_WITHOUT_URL}crematorium/crematoriumdetail/`;
    public static FLORIST_SEARCH_DETAILS = `${ApiContant.URL_WITHOUT_URL}florist/floristdetail/`;
    public static SEARCH = `${ApiContant.BASE_URL}master/search`;
    public static SEARCH_DETAILS = `${ApiContant.BASE_URL}master/searchDetailsByCountry`;
    public static TEMPLE_LIST = `${ApiContant.BASE_URL}master/temples`;
    public static TEMPLE_FEATURED_LIST = `${ApiContant.BASE_URL}master/temples/featured`;
    public static PUJAS_LIST = `${ApiContant.BASE_URL}pujaslist/`;
    public static PANDIT_LIST = `${ApiContant.BASE_URL}panditdetail/`;
    public static PANDIT_FEATURED_LIST = `${ApiContant.BASE_URL}getfeaturedpandits`;
    public static PRAYERS_LIST = `${ApiContant.BASE_URL}prayerslist/`;
    public static BLOGS_LIST = `${ApiContant.BASE_URL}bloglist/`;
    public static BLOGS_DETAILS = `${ApiContant.BASE_URL}blogdetail/`;
    public static PRAYER_DETAILS = `${ApiContant.BASE_URL}master/prayer`;
    public static COUNTRY_LIST_API = `${ApiContant.BASE_URL}countries`;
    public static STATE_LIST_API = `${ApiContant.BASE_URL}states`;
    public static CITY_LIST_API = `${ApiContant.BASE_URL}city`;
    public static ZIP_LIST_API = `${ApiContant.BASE_URL}zipbycity`;
    public static COUNTRY_CODE_API = `${ApiContant.BASE_URL}master/country/ccode`;
    public static CONTACT_US = `${ApiContant.BASE_URL}contactus/`;
    public static USER_LOGIN = `${ApiContant.BASE_URL}login/`;
    public static TEMPLE_LOGIN = `${ApiContant.URL_WITHOUT_URL}temple/login/`;
    public static TEMPLE_SIGN_UP = `${ApiContant.URL_WITHOUT_URL}temple/signup/`;
    public static USER_SIGNUP = `${ApiContant.BASE_URL}signup/`;
    public static CAREER_FORM = `${ApiContant.BASE_URL}workwithus/`;
    public static ZIPCODE_LIST = `${ApiContant.BASE_URL}suggestzip/`;
    public static USER_INFO = `${ApiContant.URL_WITHOUT_URL}user_api/user_info/`;
    public static FAVORITE_INFO = `${ApiContant.URL_WITHOUT_URL}user_api/favourites/`;
    public static REMOVE_FAVORITE_INFO = `${ApiContant.URL_WITHOUT_URL}user_api/removefromfavourites/`;
    public static TEMPLE_SUGESSION = `${ApiContant.URL}gettemples/`;
    public static PANDIT_SIGNUP = `${ApiContant.URL}panditsignup/`;
    public static PANDIT_LOGIN = `${ApiContant.URL}panditlogin/`;
    public static REGISTER_CUST_INQUIRY = `${ApiContant.URL}registercustomerquery/`;
    public static LANGUAGES = `${ApiContant.URL}getlanguages/`;
    public static PANDIT_FORGOT_PASSWORD = `${ApiContant.URL}panditpasswordchange/`;
    public static PANDIT_CHANGE_PASSWORD = `${ApiContant.URL_WITHOUT_URL}pandit_api/change-password/`;
    public static TEMPLE_FORGOT_PASSWORD = `${ApiContant.URL_WITHOUT_URL}temple/forgotpassword/`;
    public static TEMPLE_CHANGE_PASSWORD = `${ApiContant.URL_WITHOUT_URL}temple/changepassword/`;
    public static USER_FORGOT_PASSWORD = `${ApiContant.URL}userpasswordchange/`;
    public static USER_CHANGE_PASSWORD = `${ApiContant.URL_WITHOUT_URL}user_api/change-password/`;
    public static PARTNER_API_REQUEST = `${ApiContant.URL}partnershiprequest/`;
    public static FLORIST_FEATURE_API = `${ApiContant.URL_WITHOUT_URL}florist/featuredflorist/`;
    public static CREAMATORIUM_FEATURE_API = `${ApiContant.URL_WITHOUT_URL}crematorium/featuredcrematorium/`;
    public static PUJARECIPES_LIST = `${ApiContant.BASE_URL}pujarecipe/`;
    public static PUJASAMAGRI_LIST = `${ApiContant.BASE_URL}pujasamagrilist/`;
    public static PUJASAMAGRI_DETAILS = `${ApiContant.BASE_URL}pujasamagridetail/`;
    public static FLORIST_SIGNUP = `${ApiContant.URL_WITHOUT_URL}florist/signup/`;
    public static FLORIST_LOGIN = `${ApiContant.URL_WITHOUT_URL}florist/login/`;
    public static FLORIST_FORGOT_PASSWORD = `${ApiContant.URL_WITHOUT_URL}florist/forgotpassword/`;
    public static FLORIST_CHANGE_PASSWORD = `${ApiContant.URL_WITHOUT_URL}florist/changepassword/`;
    public static CREMATORIUM_SIGNUP = `${ApiContant.URL_WITHOUT_URL}crematorium/signup/`;
    public static CREMATORIUM_LOGIN = `${ApiContant.URL_WITHOUT_URL}crematorium/login/`;
    public static CREMATORIUM_FORGOT_PASSWORD = `${ApiContant.URL_WITHOUT_URL}crematorium/forgotpassword/`;
    public static CREMATORIUM_CHANGE_PASSWORD = `${ApiContant.URL_WITHOUT_URL}crematorium/changepassword/`;
    public static TEMPLE_INFO = `${ApiContant.URL_WITHOUT_URL}temple/templeinfo/`;
    public static TEMPLE_PANDIT_LIST_INFO = `${ApiContant.URL_WITHOUT_URL}temple/associate-pandits/`;
    public static ADD_PANDIT_FROM_TEMPLE_DASHBOARD = `${ApiContant.URL_WITHOUT_URL}temple/add-update-pandit/`;
    public static FLORIST_INFO = `${ApiContant.URL_WITHOUT_URL}florist/floristinfo/`;
    public static CREMATORIUM_INFO = `${ApiContant.URL_WITHOUT_URL}crematorium/creamtoriuminfo/`;
    public static UPDATE_PANDIT = `${ApiContant.URL_WITHOUT_URL}pandit_api/update-pandit/`;
    public static PANDIT_STRIPE = `${ApiContant.URL_WITHOUT_URL}pandit_api/pandit-subscription/`;
    public static FLORIST_STRIPE = `${ApiContant.URL_WITHOUT_URL}florist/florist-subscription/`;
    public static TEMPLE_STRIPE = `${ApiContant.URL_WITHOUT_URL}temple/temple-subscription/`;
    public static CREMATORIUM_STRIPE = `${ApiContant.URL_WITHOUT_URL}crematorium/crematorium-subscription/`;
    public static LANGUAGE_LIST = `${ApiContant.URL}listlanguage/`;
    public static SUBMIT_APPLICATION_FORM = `${ApiContant.BASE_URL}submitapplication/`;
    public static SOCIAL_GOOGLE_AUTH = `${ApiContant.URL_WITHOUT_URL}google/auth/`;
    public static SOCIAL_FACEBOOK_AUTH = `${ApiContant.URL_WITHOUT_URL}fb/auth`;
    public static CUSTOMER_DETAILS = `${ApiContant.BASE_URL}/getdetails`;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yoga',
  templateUrl: './yoga.component.html',
  styleUrls: ['./yoga.component.scss']
})

export class YogaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

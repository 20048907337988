<div class="god-details-container">
  <h2 class="page-header">
    <div>{{ data?.name }}</div>
    <span></span>
  </h2>
  <div class="god-details">
    <div class="back-to-list">
      <a (click)="backToList()">Back</a>
    </div>
    <div>
      <div>
        <div class="image-section" *ngIf="data?.featured_image">
          <img [src]="data?.featured_image" alt="" />
        </div>
        <p [innerHTML]="data?.description"></p>
      </div>
    </div>
  </div>
</div>

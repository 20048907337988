<div class="yoga-container">
    <div class="header">
        <h1>Astrology</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/astrology.jpg" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>In Hindu Sanskriti (culture), Vedic astrology is a system that uses nine planets that are
                        interpreted. This astrological system was developed in India 4000 to 6000 years ago and followed
                        the principles and practices of the Hindu religion.
                    </p>
                    <p>
                        The person who can calculate the positions of the planets and other celestial bodies about a
                        person's birth or happening of an event is known as Jyotisa/Jyotisha, which translates to
                        "light," Vedic astrology focuses on astral patterns of light and how those patterns influence
                        the destiny or Dharma of humans.
                    </p>
                    <p>There are two basic kinds of astrology: Sidereal and Tropical astrology. Sidereal (often used by
                        Vedic astrologers) bases the zodiac signs on the current location of the constellations, whereas
                        Tropical (used by most Western astrologers) bases the zodiac signs on the seasons.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <h2 class="col-md-12" mat-dialog-title>Pandit Review</h2>
  </div>
  <form>
    <mat-dialog-content class="mat-typography">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter name" />
          </mat-form-field>
        </div>
  
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Enter email" />
          </mat-form-field>
        </div>

        <div class="col-md-12">
            <mat-label>Review</mat-label>
            <ngx-star-rating [id]="'rating'"></ngx-star-rating>
        </div>
  
        <div class="col-md-12 MT-10">
          <mat-form-field appearance="outline">
            <mat-label>Comments</mat-label>
            <textarea matInput placeholder="Enter location of puja" rows="4" ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions align="center">
      <div class="row">
        <div class="col-md-12">
          <button mat-raised-button color="primary">Submit</button>
          <button mat-raised-button mat-dialog-close>Cancel</button>
        </div>
      </div>
    </mat-dialog-actions>
  </form>
  
<div class="testimonials-page-container">
    <div class="testimonials">
      <div class="header">
        <h1>Testimonials</h1>
  
        <ul>
          <li>
            <a class="pointer-event active">Home </a>
          </li>
  
          <li>
            <a>
              <i class="fa fa-angle-double-right"></i>
            </a>
          </li>
  
          <li>
            <a>Testimonials</a>
          </li>
          
          <li>
            <a class="active">
              <i class="fa fa-angle-double-right"></i>
            </a>
          </li>
  
          <li>
            <a>Testimonial Detail</a>
          </li>
        </ul>
      </div>
  
      <div class="testimonials__body">
        <div class="testimonials__body--section">
          <div class="testimonials__body--section--content">
            <div class="ratings">
              <mat-icon svgIcon="testimonial-star"></mat-icon>
              <mat-icon svgIcon="testimonial-star"></mat-icon>
              <mat-icon svgIcon="testimonial-star"></mat-icon>
              <mat-icon svgIcon="testimonial-star"></mat-icon>
              <mat-icon svgIcon="testimonial-star1"></mat-icon>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged.
            </p>

            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged.
            </p>

            <div class="testimonials__body--section--img">
              <img [src]="'assets/img/testimonials/testimonials-img1.png'" alt="" />
            </div>
    
            <h2>Vijay</h2>
            <h3>from India</h3>

          </div>
        </div>
      </div>
    </div>
  </div>
  
<div class="yoga-container">
    <div class="header">
        <h1>Palmistry</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/palm.jpg" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>The art or practice of supposedly interpreting a person's character or predicting their future by
                        examining the lines and other features of the hand, especially the palm and fingers.
                        (Dictionary.com)
                    </p>
                    <p>
                        There are two parts in palmistry cheirognomy refers to the pads and mounts of the palm and
                        judging the positive and negative traits in a person's philosophy. Cheriomancy relates to the
                        marks and lines of the palm in analyzing the future events of the person (askatrologer.com)
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
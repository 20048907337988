<div class="yoga-container">
    <div class="header">
        <h1>Yoga</h1>
        <ul>
            <li>
                <a class="pointer-event active">Home </a>
            </li>

            <li>
                <a class="active">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>

            <li>
                <a>Calendar</a>
            </li>
        </ul>
    </div>

    <div class="yoga-section">
        <div class="row">
            <div class="col-md-6 text-right">
                <img src="assets/img/yoga/yoga.png" />
            </div>

            <div class="col-md-6">
                <div class="yoga-section__content">
                    <p>A Hindu spiritual and ascetic discipline, a part of which, including breath control, simple
                        meditation, and the adoption of specific bodily postures, is widely practiced for health and
                        relaxation (definition Dictionary.com.)
                    </p>
                    <p>
                        Yoga is composed of a group of physical, mental, and spiritual practices or disciplines. The
                        focus
                        of yoga is on breathing and postures. These are series of poses also known as “Asanas.” These
                        Asanas
                        help increase strength and flexibility.
                    </p>
                    <p>The practice of yoga started thousands of years ago in India. At this point, it is a widely
                        popular
                        form of exercise around the world.</p>
                </div>
            </div>
        </div>
    </div>
</div>